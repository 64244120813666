<template>
	<div style="height: 100%;overflow: scroll;">

		<div class="container">
			<div class="handle-box" style="display: flex;">
				<!-- <el-input v-model="query.email" placeholder="邮箱" class="handle-input mr10"></el-input>
				<el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button> -->
				<!-- <el-button type="primary" @click="editVisible=true">換算</el-button> -->
				<el-button type="primary"  @click="exportExcel">导出EXCLE</el-button>
			<!-- 	<div style="height: 100%;text-align: center;margin-top: 10px;margin-left: 10px;color:#666666;">总金额：{{total_prices}}
				</div>
				<div style="height: 100%;text-align: center;margin-top: 10px;margin-left: 10px;color:#666666;">总u数：{{total_us}}
				</div>
 -->
				
			</div>
			<el-table id="exportTab" :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				@selection-change="handleSelectionChange" :row-class-name="tableRowClassName"
				>
				<el-table-column prop="id" label="ID"></el-table-column>
				
				<el-table-column prop="create_time" label="创建时间"></el-table-column>
				<!-- <el-table-column prop="channel_code" label="支付状态"></el-table-column> -->

				<!-- <el-table-column prop="name" label="用户名"></el-table-column> -->
				<el-table-column prop="nick_name" label="昵称"></el-table-column>
				<el-table-column prop="card"  label="身份证"></el-table-column>
				<el-table-column prop="sex_val" label="性别"></el-table-column>
				<el-table-column prop="project"  label="项目"></el-table-column>
				<el-table-column prop="tracks"  label="曲目"></el-table-column>
				<el-table-column prop="organization"  label="推荐机构"></el-table-column>
				<el-table-column prop="telephone"  label="指导老师电话"></el-table-column>
				<!-- <el-table-column prop="nums"  label="查看图片"></el-table-column> -->
				<el-table-column label="图片" align="center">
					<!-- <template #default="scope">
						<el-button type="text"  @click="select_imgs(scope.row)">查看图片</el-button>
					</template> -->
					<!-- <div class="demo-image__preview"> -->
					<template #default="scope" >
						  <el-image  
							v-if="scope.row.images_list.length>0"
							style="width: 100px; height: 100px"
							:src="scope.row.images_list[0]" 
							:preview-src-list="scope.row.images_list">
						  </el-image>
					  </template>
					<!-- </div> -->
				</el-table-column>		
				<el-table-column label="视频" align="center">
					<template #default="scope">
					          <el-button type="primary" @click="playVideo(scope.row)" ref="btn" size="mini"> 
					          播 放
					          </el-button>
					</template>
				
				</el-table-column>		
				<el-table-column prop="remakr"  label="备注"></el-table-column>
				<el-table-column label="支付状态" align="center">
					<template #default="scope">
				
						<el-tag v-if="scope.row.status ===0" type="info">未支付</el-tag>
						<el-tag v-if="scope.row.status ===1" type="danger">已支付</el-tag>
				
				
					</template>
				</el-table-column>			

				<el-table-column label="操作" width="180" fixed="right" align="center">
					<template #default="scope">
						<el-button type="text"  @click="open_remark(scope.row)">备注</el-button>
					</template>
				</el-table-column>
			

			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, prev, pager, next" :current-page="query.p"
					:page-size="query.num_of_page" :total="pageTotal" @current-change="handlePageChange">
				</el-pagination>
			</div>

		</div>
		 <el-dialog  v-model="dialogPlay" width="60%" @close = "closeDialog">
		  <!-- <video :src="videoUrl" controls  class="video" width="100%" height="500" ></video> -->
			
			<iframe width="100%" height="500" allowfullscreen :src="videoUrl"  title="YouTube video player" frameborder="0"></iframe>
			
		
		</el-dialog>
			<el-dialog title="备注" v-model="remark_editVisible" width="30%">
			    <el-form ref="form" :model="remark_form" label-width="100px">
			      <el-form-item label="备注文案">
			            <el-input v-model="remark_form.remark"></el-input>
			        </el-form-item>
			    	
			    </el-form>
			    <template #footer>
			        <span class="dialog-footer">
			            <el-button @click="remark_editVisible = false">取 消</el-button>
			            <el-button type="primary" @click="update_remaek">确 定</el-button>
			        </span>
			    </template>
			</el-dialog>

	</div>
</template>

<script>
	import FileSaver from 'file-saver'
	import * as XLSX from 'xlsx/xlsx.mjs'
	import {
		getCpaRecords,
		UConversion,
		updateCpaStatus,
		TotalDatas,
		Updatesettlement,
		GetOrders,
		UpdateRemrk

	} from "../../api/index";

	export default {
		name: "manghe_user",
		data() {
			return {
				query: {
					num_of_page: 10,
					p: 1,


				},
				srcList:[],
				remark_form:{
					remark:"",
					username:"heyuan123",
					password:"heyuan123456",
					md5:"154545",
					order_id:""
				},
				
				
				total_nums:0,
				total_prices:0,
				total_us:0,
				u_form: {
					u_price: "",
				},
				videoUrl:"",

				tableData: [],
				multipleSelection: [],
				delList: [],
				editVisible: false,
				remark_editVisible: false,
				r_coin_editVisible: false,
				dialogPlay:false,
				pageTotal: 0,
				form: {},
				inspection_form: {
					num: 0,
					user_id: ''
				},
				coin_form: {
					coin: 0,
					user_id: ''
				},
				idx: -1,
				id: -1,


			};
		},
		mounted() {
			this.getData();
			// this.total_dates()
			
		},
		methods: {
			exportExcel(){
			      /* generate workbook object from table */
			      // var xlsxParam = { raw: true } // 导出的内容只做解析，不进行格式转换
			      // var wb = XLSX.utils.table_to_book(document.querySelector('#exportTab'), xlsxParam)
				  // let fix = document.querySelector('.el-table__fixed');
			   //    let wb;
			   //    if(fix){ //判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
			   //      wb = XLSX.utils.table_to_book(document.querySelector('#exportTab').removeChild(fix));
			   //      document.querySelector('#exportTab').appendChild(fix);
			   //    }else{
			   //      wb = XLSX.utils.table_to_book(document.querySelector('#exportTab'));
			   //    }
				    var xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换
					// let fix = document.querySelector(".el-table__fixed");//如果是都给了fixed样式
					let fix = document.querySelector(".el-table__fixed-right");//如果是只有右边有fixed样式
					let wb;
					if (fix) {
					  //判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
					  wb = XLSX.utils.table_to_book(
						document.querySelector("#exportTab").removeChild(fix),
						xlsxParam
					  );
					  document.querySelector("#exportTab").appendChild(fix);
					} else {
					  wb = XLSX.utils.table_to_book(
						document.querySelector("#exportTab"),
						xlsxParam
					  );
					}
					
			      /* get binary string as output */
			      var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
			      try {
			        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '用户.xlsx')
			      } catch (e) {
			        if (typeof console !== 'undefined') {
			          console.log(e, wbout)
			        }
			      }
			      return wbout
			},
			
			select_imgs(row){
				console.log(row)
				this.srcList = row.images_list
			},
			playVideo(row) {
			      this.dialogPlay = true;
			      this.videoUrl = row.video;
			 },
				
			closeDialog() {
				this.videoUrl = ""; //清空数据 关闭视频播放

			},
			open_remark(row){
				this.remark_editVisible = true
				this.remark_form.remark = row.remakr
				this.remark_form.order_id = row.id
			},
			update_remaek(){
				UpdateRemrk(this.remark_form).then(res=>{
						console.log(res)
						if(res.code==0){
							this.$message.success("备注成功");
							this.remark_editVisible = false
							this.getData()
						}else{
							this.$message.error("备注失败");
							this.getData()
						}
					})
			},
			total_dates(){
				TotalDatas().then(res=>{
					this.total_nums = res.data.total_nums
					this.total_prices = res.data.total_prices
					this.total_us = res.data.total_us
				})
			},
			update_cpa_status(row){
				updateCpaStatus({"id":row.id}).then(res=>{
					if(res.code!=0){
						this.$message.error("标记失败");
						return
					}
					row.status_code =true
				})
			},
			tableRowClassName({row, rowIndex}) {
			        if (row.status_code === false) {
			          return 'warning-row';
			        } else {
			          return 'success-row';
			        }
			      },
			go_profit_record(row) {
				
				this.$router.push({
					'path': '/profit_record',
					'name': 'profit_record',
					query: {
						user_id: row.user
					}
				});

			},
			getData() {
				console.log("111111")
				this.query.username = "heyuan123"
				this.query.password = "heyuan123456"
				this.query.md5 = "heyuan123"
				GetOrders(this.query).then(res => {
					console.log(res.data);
					this.tableData = res.data.results;
					this.pageTotal = res.data.totals || 0;
				});
			},
			u_conversion() {
				UConversion(this.u_form).then(res => {
					if (res.code != 0) {
						this.$message.success("换算失败");
						return
					}
					this.editVisible = false
					this.getData()
				});
			},

			// 触发搜索按钮
			handleSearch() {
				// this.$set(this.query, "pageIndex", 1);
				this.getData();
				this.query.email = ''
			},
			// 删除操作
			handleDelete(index) {
				// 二次确认删除
				this.$confirm("确定要删除吗？", "提示", {
						type: "warning"
					})
					.then(() => {
						this.$message.success("删除成功");
						this.tableData.splice(index, 1);
					})
					.catch(() => {});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			delAllSelection() {
				const length = this.multipleSelection.length;
				let str = "";
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < length; i++) {
					str += this.multipleSelection[i].name + " ";
				}
				this.$message.error(`删除了${str}`);
				this.multipleSelection = [];
			},
			open_channel() {
				this.$router.push({
					'path': '/channel',
					'name': 'channel',
					query: {

					}

				});

			},
			update_num(){
				Updatesettlement().then(res => {
					if (res.code != 0) {
						this.$message.success("更新失败");
						return
					}
					this.editVisible = false
					this.getData()
				});
			},

			// 分页导航
			handlePageChange(val) {
				this.query.p = val
				this.getData();
			}
		}

	};
</script>

<style scoped>
	@import "./cpa.css";
	.el-row {
		margin-bottom: 20px;
	}
</style>
